
/* NOTES:
	to get 'instafeed' app `hash` and `key` include App Block in the customiser,
	search the document <head></head> for a script with a url of this 
	form `https://storage.nfcube.com/instafeed-a0807f468b1ab0398736311aff088290.js?shop=57a846-d9.myshopify.com`,
	open it and near the bottom will be a line `var feed = new Instafeed({`
	after which will be the `hash` and `key` to use.
*/ 

export default class Instafeed extends HTMLElement {
	limit: Number
	domain: String
	hash: String
	key: String
	locale: String
	wrapperTag: String
	wrapperProps: Object
	slideTag: String
	slideClass: String
	slideAnchorClass: String
	slideMediaClass: String
	loading: Boolean
	items: any[]

	constructor() {
		super();
		this.hash = this.getAttribute('hash');
		this.removeAttribute('hash');
		this.key = this.getAttribute('key');
		this.removeAttribute('key');
		this.locale = this.getAttribute('locale') || 'en';
		this.limit = parseInt(this.getAttribute('limit') || '10');
		this.domain = this.getAttribute('domain');
		this.removeAttribute('domain');
		this.wrapperTag = this.getAttribute('wrapper-tag') || 'div';
		const props = (this.getAttribute('wrapper-props') || '{}').replaceAll("'", '"');
		this.wrapperProps = JSON.parse(props);
		this.slideTag = this.getAttribute('slide-tag') || 'div';
		this.slideClass = this.getAttribute('slide-class') || '';
		this.slideAnchorClass = this.getAttribute('slide-anchor-class') || '';
		this.slideMediaClass = this.getAttribute('slide-media-class') || '';

		if (!this.hash)
			throw "Instafeed error: no 'hash' given";

		if (!this.domain)
			throw "Instafeed error: no 'domain' given";
	}

	connectedCallback() {
		this.getFeed();
	}

	getFeed() 
	{
		const storage = localStorage.getItem("instafeed");

		if (storage) 
		{
			const data = JSON.parse(storage);
			const date = new Date(data.timeStamp); // date last fetched
			const now = new Date(); // today

			date.setDate(date.getDate() + 1); // add 1 day onto last fetched date

			if (date < now || !data.items) 
			{
				this.loading = true;
				// if it was longer than a day ago, or is empty, refetch
				this.fetchFeed()
					.then(() => this.render());
			} 
			else 
			{
				this.items = data.items;
				this.loading = false;
				this.render()
			}
		} 
		else 
		{
			this.fetchFeed()
				.then(() => this.render());
		}
	}

	async fetchFeed()
	{
		if (!this.hash)
			throw 'Instafeed error: "hash" not defined';
		if (!this.domain)
			throw 'Instafeed error: "domain" not defined';

		try {
			const url = `https://instafeed.nfcube.com/feed/v6?fu=0&fid=0&hash=${this.hash}&locale=${this.locale}&limit=${this.limit}&account=${this.domain}`;

			fetch(url)
				.then(res => res.text())
				.then(response => {

					const data = JSON.parse(response);

					if (data) {
						this.items = data.data;
						this.loading = false;
						this.cacheFeed();
					}
			});
		} 
		catch (err) 
		{
			console.log(err);
		}
	}

	cacheFeed() 
	{
		if (!this.items) {
			console.log('Instafeed: no items to cache');
			return;
		}
		localStorage.setItem(
			"instafeed",
			JSON.stringify(
			{
				timeStamp: new Date().getTime(),
				items: this.items,
			})
		);
	}

	stringifyAttribs() {
		let ret = '';
		Object.keys(this.wrapperProps).forEach((key,index) => {
			if (typeof this.wrapperProps[key] == 'object')
				ret += `${key}="${JSON.stringify(this.wrapperProps[key]).replaceAll("\"", "'")}" `;
			else
				ret += `${key}="${this.wrapperProps[key]}" `;
		});
		return ret;
	}

	render() {
		// console.log('instafeed: ', this.items);
		let listStr = '';
		this.items.forEach((item) => {
			let innerStr = '';
			if (item.videos?.standard_resolution?.url) {
				innerStr = `<video class="${this.slideMediaClass}" loop muted autoplay src="${item.videos?.standard_resolution?.url}" aria-hidden="true"></video>`
			}
			else {
				innerStr = `<img class="${this.slideMediaClass}" src="${item.images?.standard_resolution?.url}" loading="lazy" aria-hidden="true"/>`
			}
			innerStr = `<a class="${this.slideAnchorClass}" href="${item.link}" target="_blank" title="${item.caption?.text}">${innerStr}</a>`;
			listStr += `<${this.slideTag} part="slide" class="${this.slideClass}">${innerStr}</${this.slideTag}>`;
		})
		this.innerHTML = `
				<${this.wrapperTag} part="wrapper" ${this.stringifyAttribs()}>
				${listStr}
				</${this.wrapperTag}>
		`;
	}
}
